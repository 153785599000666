.shop {
  background-color: var(--main-color);
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 100px;
  overflow-x: hidden;
}

.totalProducts {
  position: fixed;
  bottom: 64px;
  left: 40px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  background-color: #ff6093;
  font-size: 13px;
  color: #fff;
}

.cart-icon {
  color: black;
  position: fixed;
  bottom: 30px;
  left: 20px;
  font-size: 30px;
}
