.modal{
    position: fixed;
    justify-content: center;
    align-items: center;
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all ease-in-out 0.3s;
    pointer-events: none;
}

.modal.show{
    opacity: 1;
    pointer-events: visible;
}

.modal-content{
    background-color: #FFE0EA;
    transition: all ease-in-out 0.3s;
    transform: translateY(-300px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 500px;
    border-radius: 20px;
}


.Petr-Before{
    width: 34%;
    height: 300px;
    margin-right: 10px;
    border-radius: 10px;
}


.Petr-After{
    width: 47%;
    height: 300px;
    margin-right: 10px;
    border-radius: 10px;
}

.modal.show .modal-content{
    transform: translateY(0px);
}

.modal-header, .modal-footer{
    padding: 20px;
}

.modal-body{
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.modal-title{
    margin: 0;
}

.button{
    padding: 1.3em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    outline: none;
}

.button:hover{
    background-color: #FF6093;
    box-shadow: 0px 15px 20px rgba(255, 255, 255, 0.2);
    color: #fff;
    transform: translateY(-5px);
}


@media screen and (max-width: 520px ){
    .modal-content{
        width: 96%;
    }
}

@media screen and (max-width: 350px ){
    .modal-content{
        padding: 0;
    }

    .modal-header{
        padding: 0;
    }

    .modal.show{
        top: 80px;
    }

    .modal-footer{
        padding: 10px;
    }
}

