.Milan-Before{
    width: 45%;
    height: 300px;
    margin-right: 10px;
    border-radius: 10px;
}

.Milan-After{
    width: 47%;
    height: 300px;
    margin-right: 10px;
    border-radius: 10px;
}
