@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

.about-headline {
  text-align: center;
  margin-bottom: 60px;
  padding-top: 50px;
}

.headline-bigger {
  font-size: 45px;
  text-shadow: 2px 2px 4px rgba(179, 147, 211, 0.1),
    3px 4px 4px rgba(179, 147, 211, 0.15), 4px 6px 4px rgba(179, 147, 211, 0.2),
    5px 8px 4px rgba(179, 147, 211, 0.25);
  font-family: "Lobster", cursive;
  color: #ef86c1;
}

.about-text {
  margin-inline: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 420px) {
  .about-text {
    font-size: 13px;
  }
}
