nav {
  background: rgba(0, 0, 0, 0.3);
  max-width: max-content;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 1.5rem;
  display: flex;
  gap: 1rem;
  border-radius: 3rem;
  backdrop-filter: blur(10px);
  z-index: 6;
}

.nav-elements h3 {
  font-size: 10px;
  margin-top: 5px;
}

.nav-desc {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav a {
  background: transparent;
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  color: black;
  font-size: 1.1rem;
  transition: all ease 0.3s;
  justify-content: center;
}

nav a:hover {
  background-color: var(--main-color);
  color: var(--addition-color);
  cursor: pointer;
}

nav a.active {
  background-color: var(--main-color);
  color: var(--addition-color);
}

.menu-icon {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 5;
  cursor: pointer;
}

.navbar {
  position: relative;
}

.menu {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: black;
  transition: transform ease 300ms;
  top: 0;
  z-index: 7;
  transform: translateX(-100%);
}

.menu.active {
  transform: translateX(0);
  transition: transform ease 300ms;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.menu-content {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
}

.menu-link a {
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

@media screen and (max-width: 460px) {
  nav {
    display: none;
  }
}

@media screen and (min-width: 460px) {
  .menu-section {
    display: none;
  }
}

@media screen and (max-width: 350px) {
  nav {
    padding: 0.2rem 0.43rem;
    gap: 0.1rem;
  }

  .nav-elements h3 {
    font-size: 7px;
  }
}
