@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
:root {
  --main-color: #ffe0ea;
  --secondary-color: #ffa0be;
  --addition-color: #ff6093;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

.container {
  margin: 0 auto;
  width: 96%;
}

body {
  font-family: "Poppins", sans-serif;
  line-height: 1.7;
  background-size: cover;
  background-repeat: no-repeat;
}

::-webkit-scrollbar {
  display: none;
}
