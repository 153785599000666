.landing-page {
  background-color: #ffe0ea;
  position: relative;
  padding-bottom: 30px;
  height: 110dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conc {
  margin-top: 20px;
}

.landing-page::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  background-image: linear-gradient(to top, #fff, transparent);
}

.main-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  margin: auto;
  color: #393d47;
}
.main-text span {
  color: var(--addition-color);
  display: flex;
}

.main-wrapper {
  padding-top: 150px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  display: block;
  margin: 0 auto;
  width: 60%;
  min-width: 250px;
  max-width: 300px;
  text-align: center;
}

.main-logo {
  text-align: center;
}

.secondary-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #393d47;
}

.cart {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 25px;
  border: 2px solid black;
  border-radius: 40px;
  padding: 10px;
  margin: auto;
  padding-top: 20px;
  height: 50px;
  width: 50px;
  background-color: white;
}

@media screen and (max-width: 532px) {
  .main-text {
    font-size: 20px;
  }
  .secondary-text {
    font-size: 15px;
  }
  .logo {
    min-width: 140px;
  }
}

@media screen and (max-width: 460px) {
  .main-wrapper {
    padding-top: 50px;
  }
}

@media screen and (max-width: 295px) {
  .main-text {
    font-size: 17px;
  }
}
