@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');


.services{
    padding-top:50px ;
    padding-bottom:50px ;
    margin-top: 100px;
    position: relative;
}




.services-headline{
    text-align: center;
    margin-bottom: 60px;
    position: relative;
}



.headline-bigger{
    font-size: 45px;
    text-shadow: 2px 2px 4px rgba(179, 147, 211, 0.1),
    3px 4px 4px rgba(179, 147, 211, 0.15),
    4px 6px 4px rgba(179, 147, 211, 0.2),
    5px 8px 4px rgba(179, 147, 211, 0.25);
    font-family: 'Lobster', cursive;
    color: #EF86C1;
}



.services-items__wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.services-item{
    width: 390px;
    height: 300px;
    border: 3px solid #EF86C1;
    margin-right: 15px;
    margin-bottom: 30px;
    padding: 40px;
    text-align: center;
    border-radius: 40px;
    transition: all ease 0.5s;
    color: #000;
}

.services-item__link{
    text-decoration: none;
    color: black;
}

.services-item:hover{
    scale: 1.04;
}

.services-item h4{
    font-size: 20px;
    margin-bottom: 10px;
}

.services-item p{
    margin-bottom: 10px;
    font-size: 14px;
    height: 145px;
}


.services-item__button{
    padding: 1.3em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    color: #000;
    font-weight: bold;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    outline: none;
}


.services-item__button:hover {
    background-color: #FF6093;
    box-shadow: 0px 15px 20px rgba(255, 255, 255, 0.2);
    color: #fff;
    transform: translateY(-5px);
}
  
.services-item__button:active {
    transform: translateY(-1px);
}



@media screen and (max-width:768px){
    .services-item{
        margin-right: 0;
    }
}

@media screen and (max-width:420px) {
    .services-item{
        margin-right: 0;
        width: 250px;
        height: 330px;
    }

    .services-item h4{
        font-size: 15px;
    }
    .services-item p{
        font-size: 11px;
        height: 160px;
    }

    .services-item__button{
        font-size: 10px;
    }
    
    



}


