.Iveta-Before{
    width: 29%;
    height: 300px;
    margin-right: 10px;
    border-radius: 10px;
}

.modal{
    z-index: 10;
}

.Iveta-After{
    width: 40%;
    height: 300px;
    margin-right: 10px;
    border-radius: 10px;
}